import React, { useState } from "react";

const Fellowship = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    university: "",
    statement: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here (e.g., sending data to the server)
    setSubmitted(true);
  };

  return (
    <div className="bg-gray-100 py-20">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-center">
          Cultural Fellowship Application
        </h1>
        <div className="mb-8">
          <p className="text-lg mb-4">
            The New York Art Connection's Cultural Fellowship is designed to
            support aspiring artists and cultural enthusiasts from around the
            world. Our fellowship provides up to 100% financial support to
            international students who have been accepted into a U.S.
            university. We aim to foster global cultural exchange and promote
            the appreciation of diverse artistic expressions.
          </p>
          <p className="text-lg mb-4">
            Fellows will have the opportunity to immerse themselves in a vibrant
            artistic community, participate in exclusive workshops and
            exhibitions, and receive mentorship from renowned artists. This
            fellowship is not just financial support; it's a gateway to a
            thriving cultural network and a chance to make a significant impact
            in the world of art and culture.
          </p>
        </div>
        {submitted ? (
          <div className="bg-green-100 text-green-800 p-4 rounded mb-8">
            Your application has been successfully submitted. Thank you for
            applying!
          </div>
        ) : (
          <form
            className="max-w-lg mx-auto bg-white p-8 rounded shadow-md"
            onSubmit={handleSubmit}
          >
            <div className="mb-4">
              <label className="block text-left mb-2" htmlFor="name">
                Name
              </label>
              <input
                className="w-full p-2 border rounded"
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-left mb-2" htmlFor="email">
                Email
              </label>
              <input
                className="w-full p-2 border rounded"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-left mb-2" htmlFor="university">
                University
              </label>
              <input
                className="w-full p-2 border rounded"
                type="text"
                id="university"
                name="university"
                value={formData.university}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-left mb-2" htmlFor="statement">
                Personal Statement
              </label>
              <textarea
                className="w-full p-2 border rounded"
                id="statement"
                name="statement"
                value={formData.statement}
                onChange={handleChange}
                required
              />
            </div>
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded"
              type="submit"
            >
              Submit
            </button>
          </form>
        )}
      </div>{" "}
    </div>
  );
};

export default Fellowship;
