import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-gray-600">
          <img
            src="/logo.jpg"
            alt="New York Art Connection Logo"
            className="w-32"
          />{" "}
        </Link>
        <div className="hidden md:flex space-x-4">
          <Link to="/" className="text-gray-600">
            Home
          </Link>
          <Link to="/about" className="text-gray-600">
            About
          </Link>
          <Link to="/director-message" className="text-gray-600">
            Director's Message
          </Link>
          <Link to="/contact-us" className="text-gray-600">
            Contact Us
          </Link>
        </div>
        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)} className="text-gray-600">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              />
            </svg>
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden bg-white p-4">
          <Link to="/" className="block text-gray-600 mb-2">
            Home
          </Link>
          <Link to="/about" className="block text-gray-600 mb-2">
            About
          </Link>
          <Link to="/contact-us" className="block text-gray-600 mb-2">
            Contact Us
          </Link>
          <Link to="/director-message" className="block text-gray-600">
            Director's Message
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
