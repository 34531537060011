import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Fellowship from "./pages/Fellowship";
import DirectorMessage from "./pages/DirectorMessage"; // Import the new page
import ContactUs from "./pages/Contact";
import "./index.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/fellowship" element={<Fellowship />} />
          <Route path="/director-message" element={<DirectorMessage />} />{" "}
          <Route path="/contact-us" element={<ContactUs />} />
          {/* Add the new route */}
        </Routes>
      </div>
      <footer className="bg-gray-800 text-white py-8 text-center">
        <div>
          <p className="font-bold">The New York Art Connection</p>
          <p>67, Subburst Terrace, Central Islip</p>
          <p>NY 11722, USA</p>
          <p>
            Email:{" "}
            <a
              href="mailto:thenyartconnection@gmail.com"
              className="text-blue-400 underline"
            >
              thenyartconnection@gmail.com
            </a>
          </p>
          <p>
            Phone:{" "}
            <a href="tel:+16315327368" className="text-blue-400 underline">
              +1 (631) 532-7368
            </a>
          </p>
        </div>
      </footer>
    </Router>
  );
}

export default App;
